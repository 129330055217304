<template>
  <div id="newsStoryContent">
    <!-- picC0B9A29000BE007FC878B0193101AAF4.jpg -->
    <img id="Image1" class="rounded img-large" src="@/assets/news-network/the-great-minifig-mission.jpg">
    <p id="Text1">
      The Great Minifig Mission is underway and many of you are already helping the minifigs
      answer the call and save imagination. By logging in with your LEGO ID and taking part
      in missions, thousands are helping to send minifigs into LEGO Universe to take on the
      power of The Maelstrom. As we decipher the data flowing in from LEGO Universe, more
      missions will be added for you to take. Keep up the amazing work!
      <br><br>
      There are troubling signs, however, that the evil force known as The Maelstrom has
      infiltrated computer systems at LEGOUniverse.com
      <br><br>
      “While we are certain that there are minifigs being sent through to LEGO Universe, we
      believe The Maelstrom may be exaggerating the total number,” said Dr John Deutschendorf
      from the Bradford Rant Institute. “It may be doing this in order to discourage people
      from taking the missions. If they think there are enough minifigs in LEGO Universe,
      they may not help out anymore. We would strongly urge LEGO fans to continue taking the
      missions and help the minifigs!”
      <br><br>
      Join the Great Minifig Mission
      <router-link to="/help-the-minifigs">now!</router-link>
    </p>
</div>
</template>
